.App {
  text-align: center;
}

.social .fbtn {
  width: 50px;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 18px;
  float: left;
}

.social .fa {
  padding: 15px 0px
}

.facebook {
  background-color: #3b5998;
}

.gplus {
  background-color: #dd4b39;
}

.twitter {
  background-color: #55acee;
}

.stumbleupon {
  background-color: #eb4924;
}

.pinterest {
  background-color: #cc2127;
}

.linkedin {
  background-color: #0077b5;
}

.buffer {
  background-color: #323b43;
}

.share-button.sharer {
  height: 20px;
  padding: 100px;
}

.share-button.sharer .social.active.top {
  transform: scale(1) translateY(-10px);
}

.share-button.sharer .social.active {
  opacity: 1;
  transition: all 0.4s ease 0s;
  visibility: visible;
}

.share-button.sharer .social.networks-5 {}

.share-button.sharer .social.top {
  margin-top: -80px;
  transform-origin: 0 0 0;
}

.share-button.sharer .social {
  margin-left: -65px;
  opacity: 0;
  transition: all 0.4s ease 0s;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}